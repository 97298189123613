// extracted by mini-css-extract-plugin
export const title1 = "typography-module--title1--2V6oE";
export const title2 = "typography-module--title2--2wY1R";
export const title3 = "typography-module--title3--2tApv";
export const large = "typography-module--large--1fhJb";
export const base = "typography-module--base--22Eed";
export const small = "typography-module--small--1QMIr";
export const micro = "typography-module--micro--14igZ";
export const paragraph = "typography-module--paragraph--1koIe";
export const blockQuote = "typography-module--blockQuote--2MkHU";
export const responsiveTitle1 = "typography-module--responsiveTitle1--d7HQu";
export const responsiveTitle2 = "typography-module--responsiveTitle2--3tlze";
export const responsiveTitle3 = "typography-module--responsiveTitle3--3hTlY";
export const responsiveTitle4 = "typography-module--responsiveTitle4--3MyJx";